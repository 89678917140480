/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const Score = props => {
  // console.log("props", props);
  const team = props.className;
  const teamName = props.teamName;
  const teamScore = props.teamScore;
  const backgroundColor = team === "away-team" ? "away-team" : "home-team";
  // console.log("backgroundColor", backgroundColor);

  return (
    <button className={`stat score-stat ${backgroundColor}`}>
      {teamName}: {teamScore}
    </button>
  );
};

export default Score;
