export const addTeamNamesToMatches = (matches, teams) => {
  matches.forEach(match => {
    let awayTeamObj = teams.find(team => {
      return match.awayTeam === team.id;
    });
    match.awayTeamName = awayTeamObj.teamName;
    let homeTeamObj = teams.find(team => {
      return match.homeTeam === team.id;
    });
    match.homeTeamName = homeTeamObj.teamName;
    // console.log("match", match);
  });
  // console.log("addTeamNamesToMatches", addTeamNamesToMatches);
  if (typeof Storage !== "undefined") {
    localStorage.setItem("matches", JSON.stringify(matches));
  }
  return matches;
};
