import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import LinkListHooks from "./LinkListHooks";
import "./../styles/App.css";
import CreateLink from "./CreateLink";
import Header from "./Header";
import Login from "./Login";
import Search from "./Search";
import MyCurrentMatch from "./Matches/Match/MyCurrentMatch";
import CurrentMatches from "./Matches/CurrentMatches";

function App() {
  const client = useApolloClient();
  if (
    typeof Storage !== "undefined" &&
    localStorage.hasOwnProperty("matches")
  ) {
    let matches = JSON.parse(localStorage.getItem("matches"));
    // console.log("matches", matches);
    client.writeData({ data: { matches: matches } });
  }
  return (
    <div className="center w85">
      <Header />
      <div className="ph3 pv1 background-gray">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/matches" />} />
          <Route exact path="/create" component={CreateLink} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/top" component={LinkListHooks} />
          <Route exact path="/new/:page" component={LinkListHooks} />
          <Route exact path="/matches" component={CurrentMatches} />
          <Route exact path="/my-current/match" component={MyCurrentMatch} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
