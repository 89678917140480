import React from "react";
import PitchStat from "./PitchStat";

const PitchCount = pitchCount => {
  let { balls, strikes, fouls, outs } = pitchCount.pitchCount;
  let currentInning = pitchCount.match.currentInning;

  return (
    <div className="pitch-count ">
      <div className="pitch-count-center">
        <PitchStat className="balls" statName={"B"} statValue={balls} />
        <PitchStat className="fouls" statName={"F"} statValue={fouls} />
      </div>
      <div className="pitch-count-center">
        <PitchStat className="strikes" statName={"S"} statValue={strikes} />
        <PitchStat className="outs" statName={"O"} statValue={outs} />
      </div>
      {/* <div className="scores">
        <h3>{currentInning}</h3>
      </div> */}
    </div>
  );
};

export default PitchCount;
