export const innings = [
  "Top 1st",
  "Bottom 1st",
  "Top 2nd",
  "Bottom 2nd",
  "Top 3rd",
  "Bottom 3rd",
  "Top 4th",
  "Bottom 4th",
  "Top 5th",
  "Bottom 5th",
  "Top 6th",
  "Bottom 6th",
  "MATCH OVER"
];
