import React from "react";
import Score from "./Score";

const ScoreBoard = scoreBoard => {
  let {
    homeTeam,
    awayTeam,
    homeTeamScore,
    awayTeamScore
  } = scoreBoard.scoreBoard;

  return (
    <div className="score-board">
      <div className="scores">
        <Score
          className="away-team"
          teamName={awayTeam}
          teamScore={awayTeamScore}
        />
        <Score
          className="home-team"
          teamName={homeTeam}
          teamScore={homeTeamScore}
        />
      </div>
    </div>
  );
};

export default ScoreBoard;
