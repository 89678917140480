import React from "react";
import userImage from "./../../../images/userImage.png";

function Kicker(props) {
  const userKick = props.userKick;
  return (
    <div onClick={() => userKick()} className="kicker-card">
      <img
        src={userImage}
        className="kicker"
        alt="profile pic of player up to kick"
      />
      <div>
        the commish is up to kick. <hr />
        Bunted last time.
      </div>
    </div>
  );
}

export default Kicker;
