import gql from "graphql-tag";
import { MATCH_FEED_QUERY } from "./Components/Matches/Matches";

export const schema = gql`
  type Link {
    id: ID!
    description: String!
    url: String!
    postedBy: User
    votes: [Vote!]!
  }

  type AuthPayload {
    token: String
    user: User
  }

  type User {
    id: ID!
    name: String!
    email: String!
    links: [Link!]!
  }

  type Vote {
    id: ID!
    link: Link!
    user: User!
  }

  type Feed {
    links: [Link!]!
    count: Int!
  }

  type TeamFeed {
    teams: [Team!]!
    teamCount: Int!
  }

  type Team {
    id: ID!
    teamName: String!
    manager: User
    wins: Int
    losses: Int
    ties: Int
    championships: Int
  }

  type MatchFeed {
    matches: [Match!]!
    matchCount: Int!
  }

  """
  Match type has a User as a scoreKeeper, two teams each of type Team, an array of Player types for each teams kicking order, score field for each team,various location props, a field prop.
  current inning and matchType are enums.
  balls, strikes, fouls, and outs are Int type, a matchLength prop, lengthMeasured prop and a startTime prop
  """
  type Match {
    id: ID!
    scoreKeeper: User!
    awayTeam: String!
    homeTeam: String!
    awayTeamScore: Int
    homeTeamScore: Int
    currentInning: String!
    matchType: String!
    balls: Int
    strikes: Int
    fouls: Int
    outs: Int
    extraInnings: Boolean!
    encroachmentWarning: Boolean!
    matchComplete: Boolean!
  }

  enum LinkOrderByInput {
    description_ASC
    description_DESC
    url_ASC
    url_DESC
    createdAt_ASC
    createdAt_DESC
  }

  enum MatchOrderByInput {
    awayTeam_ASC
    awayTeam_DESC
    homeTeam_ASC
    homeTeam_DESC
    awayTeamScore_ASC
    awayTeamScore_DESC
    homeTeamScore_ASC
    homeTeamScore_DESC
  }

  enum TeamOrderByInput {
    teamName_ASC
    teamName_DESC
    wins_ASC
    wins_DESC
    losses_ASC
    losses_DESC
    ties_ASC
    ties_DESC
    championships_ASC
    championships_DESC
  }

  enum Innings {
    TOP_1ST
    BOTTOM_1ST
    TOP_2ND
    BOTTOM_2ND
    TOP_3RD
    BOTTOM_3RD
    TOP_4TH
    BOTTOM_4TH
    TOP_5TH
    BOTTOM_5TH
    TOP_6TH
    BOTTOM_6TH
    MATCH_OVER
  }

  enum MatchType {
    FRIENDLY
    SEASON
    PLAYOFF
    TOURNAMENT
    CHARITY
  }
`;

export const typeDefs = gql`
  type Query {
    isLoggedIn: Boolean!
    info: String!
    feed(
      filter: String
      skip: Int
      first: Int
      last: Int
      orderBy: LinkOrderByInput
    ): Feed!
    matchFeed(
      filter: String
      skip: Int
      first: Int
      last: Int
      orderBy: MatchOrderByInput
    ): MatchFeed!
    teamFeed(
      filter: String
      skip: Int
      first: Int
      last: Int
      orderBy: TeamOrderByInput
    ): TeamFeed!
  }

  type Mutation {
    post(url: String!, description: String!): Link!
    signup(email: String!, password: String!, name: String!): AuthPayload
    login(email: String!, password: String!): AuthPayload
    vote(linkId: ID!): Vote
    createMatch(awayTeam: String!, homeTeam: String!): Match!
    updateMatch(
      id: ID!
      awayTeamScore: Int
      homeTeamScore: Int
      balls: Int
      strikes: Int
      fouls: Int
      outs: Int
      currentInning: String
    ): Match!
    createTeam(teamName: String!): Team!
  }

  type Subscription {
    newLink: Link
    newVote: Vote
    subNewMatch: Match
    subUpdateMatch: Match
  }

  type Link {
    id: ID!
    description: String!
    url: String!
    postedBy: User
    votes: [Vote!]!
  }

  type AuthPayload {
    token: String
    user: User
  }

  type User {
    id: ID!
    name: String!
    email: String!
    links: [Link!]!
  }

  type Vote {
    id: ID!
    link: Link!
    user: User!
  }

  type Feed {
    links: [Link!]!
    count: Int!
  }

  type TeamFeed {
    teams: [Team!]!
    teamCount: Int!
  }

  type Team {
    id: ID!
    teamName: String!
    manager: User
    wins: Int
    losses: Int
    ties: Int
    championships: Int
  }

  type MatchFeed {
    matches: [Match!]!
    matchCount: Int!
  }

  """
  Match type has a User as a scoreKeeper, two teams each of type Team, an array of Player types for each teams kicking order, score field for each team,various location props, a field prop.
  current inning and matchType are enums.
  balls, strikes, fouls, and outs are Int type, a matchLength prop, lengthMeasured prop and a startTime prop
  """
  type Match {
    id: ID!
    scoreKeeper: User!
    awayTeam: String!
    homeTeam: String!
    awayTeamScore: Int
    homeTeamScore: Int
    currentInning: String!
    matchType: String!
    balls: Int
    strikes: Int
    fouls: Int
    outs: Int
    extraInnings: Boolean!
    encroachmentWarning: Boolean!
    matchComplete: Boolean!
  }

  enum LinkOrderByInput {
    description_ASC
    description_DESC
    url_ASC
    url_DESC
    createdAt_ASC
    createdAt_DESC
  }

  enum MatchOrderByInput {
    awayTeam_ASC
    awayTeam_DESC
    homeTeam_ASC
    homeTeam_DESC
    awayTeamScore_ASC
    awayTeamScore_DESC
    homeTeamScore_ASC
    homeTeamScore_DESC
  }

  enum TeamOrderByInput {
    teamName_ASC
    teamName_DESC
    wins_ASC
    wins_DESC
    losses_ASC
    losses_DESC
    ties_ASC
    ties_DESC
    championships_ASC
    championships_DESC
  }

  enum Innings {
    TOP_1ST
    BOTTOM_1ST
    TOP_2ND
    BOTTOM_2ND
    TOP_3RD
    BOTTOM_3RD
    TOP_4TH
    BOTTOM_4TH
    TOP_5TH
    BOTTOM_5TH
    TOP_6TH
    BOTTOM_6TH
    MATCH_OVER
  }

  enum MatchType {
    FRIENDLY
    SEASON
    PLAYOFF
    TOURNAMENT
    CHARITY
  }
`;

export const resolvers = {
  MatchFeed: {
    matches: (match, _, { cache }) => {
      const { matchFeed } = cache.readQuery({ query: MATCH_FEED_QUERY });
      return matchFeed.includes(match);
    }
  }
};
