import React, { Component, Fragment } from "react";
// import { Query } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import AppLink from "./AppLink";
import { LINKS_PER_PAGE } from "./../constants";

const NEW_VOTES_SUBSCRIPTION = gql`
  subscription {
    newVote {
      id
      link {
        id
        url
        description
        postedBy {
          id
          name
        }
        votes {
          id
          user {
            id
          }
        }
      }
      user {
        id
      }
    }
  }
`;

const NEW_LINKS_SUBSCRIPTION = gql`
  subscription {
    newLink {
      id
      url
      description
      postedBy {
        id
        name
      }
      votes {
        id
        user {
          id
        }
      }
    }
  }
`;

export const FEED_QUERY = gql`
  query FeedQuery($first: Int, $skip: Int, $orderBy: LinkOrderByInput) {
    feed(first: $first, skip: $skip, orderBy: $orderBy) {
      links {
        id
        url
        description
        postedBy {
          id
          name
        }
        votes {
          id
          user {
            id
          }
        }
      }
      count
    }
  }
`;

const LinkListHooks = () => {
  const { data, loading, error, fetchMore } = useQuery(FEED_QUERY);
  if (loading) return <div>Fetching</div>;
  console.log("error", error);
  if (error) return <div>Error</div>;

  const getLinksToRender = data => {
    // console.log("data", data);

    const rankedLinks = data.feed.links.slice();
    rankedLinks.sort((l1, l2) => l2.votes.length - l1.votes.length);
    return rankedLinks;
  };

  const linksToRender = getLinksToRender(data);
  return (
    <Fragment>
      {linksToRender.map((link, index) => (
        <AppLink key={link.id} link={link} index={index} />
      ))}
    </Fragment>
  );
};

export default LinkListHooks;
