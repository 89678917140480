import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Kicker from "./Kicker";

import { innings } from "./../../../helper_functions/kickballConstants";
import { MATCH_FEED_QUERY } from "./../Matches";

export const UPDATE_MATCH = gql`
  mutation UpdateMatch(
    $id: ID!
    $awayTeamScore: Int!
    $homeTeamScore: Int!
    $balls: Int!
    $strikes: Int!
    $fouls: Int!
    $outs: Int!
    $currentInning: String!
  ) {
    updateMatch(
      id: $id
      awayTeamScore: $awayTeamScore
      homeTeamScore: $homeTeamScore
      balls: $balls
      strikes: $strikes
      fouls: $fouls
      outs: $outs
      currentInning: $currentInning
    ) {
      id
      awayTeamScore
      homeTeamScore
      balls
      strikes
      fouls
      outs
      currentInning
      matchComplete
    }
  }
`;

const MyCurrentMatch = props => {
  const client = useApolloClient();
  const [updateMyCurrentMatch, { data, loading, error }] = useMutation(
    UPDATE_MATCH
  );
  // console.log("data", data);
  // console.log("loading", loading);
  // console.log("error", error);
  // console.log("client", client);
  // console.log("props", props);
  let { match, pitchCount, scoreBoard } = props.location.state;
  // console.log("match", match);

  let { currentInning, matchId } = match;
  const id = matchId;
  match.id = id;
  let { awayTeam, homeTeam } = scoreBoard;

  const [awayTeamScore, setAwayTeamScore] = useState(scoreBoard.awayTeamScore);
  const [homeTeamScore, setHomeTeamScore] = useState(scoreBoard.homeTeamScore);
  const [balls, setBalls] = useState(pitchCount.balls);
  const [strikes, setStrikes] = useState(pitchCount.strikes);
  const [fouls, setFouls] = useState(pitchCount.fouls);
  const [outs, setOuts] = useState(pitchCount.outs);

  const userKick = () => {
    setBalls(0);
    setStrikes(0);
    setFouls(0);
    match.balls = 0;
    match.strikes = 0;
    match.fouls = 0;
    updateMatch(match);
  };

  const checkForWalksAndOuts = () => {
    if (fouls > 2 || strikes > 1) {
      setBalls(0);
      setStrikes(0);
      setFouls(0);
      setOuts(outs + 1);
      match.balls = 0;
      match.strikes = 0;
      match.fouls = 0;
      match.outs = outs + 1;
    } else if (balls > 2) {
      setBalls(0);
      setStrikes(0);
      setFouls(0);
      match.balls = 0;
      match.strikes = 0;
      match.fouls = 0;
      console.log("walk");
    }
    if (outs > 1) {
      switch (match.currentInning) {
        case "Top 1st":
          match.currentInning = innings[1];
          break;
        case "Bottom 1st":
          match.currentInning = innings[2];
          break;
        case "Top 2nd":
          match.currentInning = innings[3];
          break;
        case "Bottom 2nd":
          match.currentInning = innings[4];
          break;
        case "Top 3rd":
          match.currentInning = innings[5];
          break;
        case "Bottom 3rd":
          match.currentInning = innings[6];
          break;
        case "Top 4th":
          match.currentInning = innings[7];
          break;
        case "Bottom 4th":
          match.currentInning = innings[8];
          break;
        case "Top 5th":
          match.currentInning = innings[9];
          break;
        case "Bottom 5th":
          match.currentInning = innings[10];
          break;
        case "Top 6th":
          match.currentInning = innings[11];
          break;
        case "Bottom 6th":
          match.currentInning = innings[12];
          break;
        default:
          break;
      }
      setBalls(0);
      setStrikes(0);
      setFouls(0);
      setOuts(0);
      match.balls = 0;
      match.strikes = 0;
      match.fouls = 0;
      match.outs = 0;
    }
    updateMatch(match);
  };

  const changeHomeTeamScore = async scoreBoard => {
    setHomeTeamScore(homeTeamScore + 1);
    match.homeTeamScore = homeTeamScore + 1;
    return updateMatch(match);
  };

  const changeAwayTeamScore = async scoreBoard => {
    setAwayTeamScore(awayTeamScore + 1);
    match.awayTeamScore = awayTeamScore + 1;
    return updateMatch(match);
  };

  const changeBalls = async scoreBoard => {
    setBalls(balls + 1);
    match.balls = balls + 1;
    return checkForWalksAndOuts();
  };

  const changeStrikes = async scoreBoard => {
    setStrikes(strikes + 1);
    match.strikes = strikes + 1;
    return checkForWalksAndOuts();
  };

  const changeFouls = async scoreBoard => {
    setFouls(fouls + 1);
    match.fouls = fouls + 1;
    return checkForWalksAndOuts();
  };

  const changeOuts = async scoreBoard => {
    setBalls(0);
    setStrikes(0);
    setFouls(0);
    match.balls = 0;
    match.strikes = 0;
    match.fouls = 0;
    setOuts(outs + 1);
    match.outs = outs + 1;
    return checkForWalksAndOuts();
  };

  // const updateMyCurrentMatch = () => {
  //   console.log("match", match);
  //   client.writeData({ data: { myCurrentMatch: match } });
  // };

  const updateMatch = async updatedMatch => {
    // console.log("updatedMatch", updatedMatch);
    // console.log("JSON.stringify(updatedMatch)", JSON.stringify(updatedMatch));
    updateMyCurrentMatch({
      variables: {
        id: updatedMatch.id,
        awayTeamScore: updatedMatch.awayTeamScore,
        homeTeamScore: updatedMatch.homeTeamScore,
        balls: updatedMatch.balls,
        strikes: updatedMatch.strikes,
        fouls: updatedMatch.fouls,
        outs: updatedMatch.outs,
        currentInning: updatedMatch.currentInning
      }
    });
  };

  return (
    <div className="my-current-match">
      <h3 className="scores">My Current Match: {currentInning}</h3>
      <Kicker userKick={userKick} />
      <div className="score-board">
        <button
          onClick={() => changeAwayTeamScore()}
          className="score-stat score away-team"
        >
          {awayTeam}: {awayTeamScore}
        </button>
        <button
          onClick={() => changeHomeTeamScore()}
          className="score-stat score home-team"
        >
          {homeTeam}: {homeTeamScore}
        </button>
      </div>
      <div className="pitch-count">
        <button onClick={() => changeBalls()} className="stat pitch-stat balls">
          Balls: {balls}
        </button>
        <button
          onClick={() => changeStrikes()}
          className="stat pitch-stat strikes"
        >
          Strikes: {strikes}
        </button>
      </div>
      <div className="pitch-count">
        <button onClick={() => changeFouls()} className="stat pitch-stat fouls">
          Fouls: {fouls}
        </button>
        <button onClick={() => changeOuts()} className="stat pitch-stat outs">
          Outs: {outs}
        </button>
      </div>
    </div>
  );
};

export default MyCurrentMatch;
