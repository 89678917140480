/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PitchStat = props => {
  // console.log("props", props);
  const statClassName = props.className;
  const statName = props.statName;
  const statValue = props.statValue;
  const backgroundColor =
    statClassName === "balls"
      ? "balls"
      : statClassName === "strikes"
      ? "strikes"
      : statClassName === "fouls"
      ? "fouls"
      : "outs";
  // console.log("backgroundColor", backgroundColor);
  return (
    <button className={`stat pitch-stat ${backgroundColor}`}>
      {statName}: {statValue}
    </button>
    // <svg width={118} height={63} viewBox="0 0 118 63">
    //   <defs>
    //     <style>
    //       {
    //         ".a{fill:#05f76b;}.b{fill:#fff;stroke:#707070;font-size:34px;font-family:Arial-BoldMT, Arial;font-weight:700;}.c{filter:url(#c);}.d{filter:url(#a);}"
    //       }
    //     </style>
    //     <filter
    //       id="a"
    //       x={0}
    //       y={2}
    //       width={118}
    //       height={61}
    //       filterUnits="userSpaceOnUse"
    //     >
    //       <feOffset dy={3} input="SourceAlpha" />
    //       <feGaussianBlur stdDeviation={3} result="b" />
    //       <feFlood floodColor="#e1e1e1" />
    //       <feComposite operator="in" in2="b" />
    //       <feComposite in="SourceGraphic" />
    //     </filter>
    //     <filter
    //       id="c"
    //       x={17.223}
    //       y={0}
    //       width={74}
    //       height={58}
    //       filterUnits="userSpaceOnUse"
    //     >
    //       <feOffset dy={3} input="SourceAlpha" />
    //       <feGaussianBlur stdDeviation={3} result="d" />
    //       <feFlood floodOpacity={0.161} />
    //       <feComposite operator="in" in2="d" />
    //       <feComposite in="SourceGraphic" />
    //     </filter>
    //   </defs>
    //   <g transform="translate(9 7)">
    //     <g className="d" transform="matrix(1, 0, 0, 1, -9, -7)">
    //       <rect
    //         className="a"
    //         width={100}
    //         height={43}
    //         rx={10}
    //         transform="translate(9 8)"
    //       />
    //     </g>
    //     <g className="c" transform="matrix(1, 0, 0, 1, -9, -7)">
    //       <text className="b" transform="translate(54.22 38)">
    //         <tspan x={-26.455} y={0}>
    //           {"B 3"}
    //         </tspan>
    //       </text>
    //     </g>
    //   </g>
    // </svg>
  );
};

export default PitchStat;
