import React from "react";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import PitchCount from "./PitchCount";
import ScoreBoard from "./ScoreBoard";
import { useSubscription } from "@apollo/react-hooks";

const SUB_UPDATE_MATCH = gql`
  subscription SubUpdateMatch {
    subUpdateMatch {
      id
      awayTeamScore
      homeTeamScore
      balls
      strikes
      fouls
      outs
      currentInning
    }
  }
`;

const Match = props => {
  const { data, loading, error } = useSubscription(SUB_UPDATE_MATCH);
  // let scoreBoard = {
  //   homeTeam: props.match.homeTeamName,
  //   awayTeam: props.match.awayTeamName,
  //   homeTeamScore: props.match.homeTeamScore,
  //   awayTeamScore: props.match.awayTeamScore
  // };
  // let pitchCount = {
  //   balls: props.match.balls,
  //   strikes: props.match.strikes,
  //   fouls: props.match.fouls,
  //   outs: props.match.outs
  // };
  // console.log("data", data);

  let match = {
    id: props.match.id,
    matchId: props.match.id,
    currentInning: props.match.currentInning,
    matchComplete: props.match.matchComplete,
    homeTeam: props.match.homeTeamName,
    awayTeam: props.match.awayTeamName,
    homeTeamScore: props.match.homeTeamScore,
    awayTeamScore: props.match.awayTeamScore,
    balls: props.match.balls,
    strikes: props.match.strikes,
    fouls: props.match.fouls,
    outs: props.match.outs
  };
  let scoreBoard = {
    homeTeam: props.match.homeTeamName,
    awayTeam: props.match.awayTeamName,
    homeTeamScore: props.match.homeTeamScore,
    awayTeamScore: props.match.awayTeamScore
  };
  let pitchCount = {
    balls: props.match.balls,
    strikes: props.match.strikes,
    fouls: props.match.fouls,
    outs: props.match.outs
  };

  if (!loading && data.subUpdateMatch.id === match.id) {
    // console.log("data.subUpdateMatch", data.subUpdateMatch);
    match.currentInning = props.match.currentInning;
    match.matchComplete = props.match.matchComplete;
    match.homeTeam = props.match.homeTeamName;
    match.awayTeam = props.match.awayTeamName;
    match.homeTeamScore = props.match.homeTeamScore;
    match.awayTeamScore = props.match.awayTeamScore;
    match.balls = props.match.balls;
    match.strikes = props.match.strikes;
    match.fouls = props.match.fouls;
    match.outs = props.match.outs;
    scoreBoard.homeTeam = props.match.homeTeamName;
    scoreBoard.awayTeam = props.match.awayTeamName;
    scoreBoard.homeTeamScore = props.match.homeTeamScore;
    scoreBoard.awayTeamScore = props.match.awayTeamScore;
    pitchCount.balls = props.match.balls;
    pitchCount.strikes = props.match.strikes;
    pitchCount.fouls = props.match.fouls;
    pitchCount.outs = props.match.outs;
  }
  return (
    <Link
      to={{
        pathname: "/my-current/match",
        state: { match, scoreBoard, pitchCount }
      }}
      className="match-link"
    >
      <div className={match.matchComplete ? "match" : "match currentlyPlaying"}>
        <ScoreBoard scoreBoard={scoreBoard} />
        <PitchCount pitchCount={pitchCount} match={match} />
      </div>
    </Link>
  );
};

export default Match;
