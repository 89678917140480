import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { AUTH_TOKEN } from "../constants";
import "./../styles/Header.css";

const Header = props => {
  const authToken = localStorage.getItem(AUTH_TOKEN);

  return (
    <nav className="navbar">
      <div className="header dropdown">
        <div id="matches-dropdown1" className="dropdown1">
          <Link to={"/"} className="btn btn-default">
            Bush League Kickball
            <br /> Alpha Version
          </Link>
          <div className="content" id="matches-content1">
            <Link to="/matches" className="btn btn-default">
              Matches
            </Link>
            <Link to="/create" className="btn btn-default">
              new link
            </Link>
            <Link to="/top" className="btn btn-default">
              top
            </Link>
            {authToken && (
              <div className="flex">
                <Link to="/create" className="ml1 no-underline black">
                  submit
                </Link>
              </div>
            )}
            <div className="flex flex-fixed">
              {authToken ? (
                <div
                  className="ml1 pointer black"
                  onClick={() => {
                    localStorage.removeItem(AUTH_TOKEN);
                    props.history.push("/");
                  }}
                >
                  <Link to="/" className="btn btn-default">
                    logout
                  </Link>
                </div>
              ) : (
                <Link to="/login" className="btn btn-default">
                  login
                </Link>
              )}
            </div>
          </div>
          <div className="dropdown1" id="matches-dropdown2"></div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Header);
