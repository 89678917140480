import React, { Fragment } from "react";
import {
  useApolloClient,
  useQuery,
  useSubscription
} from "@apollo/react-hooks";
import gql from "graphql-tag";
import { addTeamNamesToMatches } from "./../../helper_functions/addTeamNameToMatches";
import Match from "./Match/Match";

import { MATCH_FEED_QUERY } from "./Matches";

const CURRENT_MATCH_FEED_QUERY = gql`
  query CurrentMatchFeedQuery {
    matches @client
  }
`;

export default function CurrentMatches() {
  if (typeof Storage !== "undefined") {
    // console.log("localStorage", localStorage);
  }
  const { data, loading, error } = useQuery(MATCH_FEED_QUERY);
  // console.log("data", data);
  // console.log("loading", loading);
  // console.log("error", error);

  if (loading) return <div>Fetching Matches...</div>;
  if (error) return <div>Error</div>;
  const matches = data.matchFeed.matches;
  const teams = data.teamFeed.teams;
  const matchesWithName = addTeamNamesToMatches(matches, teams);
  // console.log("matchesWithName", matchesWithName);
  return (
    <Fragment>
      <div>CurrentMatches</div>
      {
        <Fragment>
          {matchesWithName.map((match, index) => (
            <Match key={match.id} match={match} index={index} />
          ))}
        </Fragment>
      }
    </Fragment>
  );
}
