import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App";
import LinkListHooks from "./Components/LinkListHooks";
import Login from "./Components/Login";
import * as serviceWorker from "./serviceWorker";

import { split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";

import { ApolloClient } from "apollo-client";
import { ApolloProvider, useQuery } from "@apollo/react-hooks";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import gql from "graphql-tag";
import { InMemoryCache } from "apollo-cache-inmemory";
import { AUTH_TOKEN } from "./constants";

import { resolvers, typeDefs } from "./resolvers";

ReactGA.initialize("UA-146422046-2", {
  debug: true
});

ReactGA.pageview(window.location.pathname + window.location.search);

hotjar.initialize(1463334, 6);

const supportsHistory = "pushState" in window.history;

// dev server
const httpLink = createHttpLink({
  uri: "http://localhost:4000"
});
// prod server
// const httpLink = createHttpLink({
//   uri: "https://young-dawn-56853.herokuapp.com"
// });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// dev server
const wsLink = new WebSocketLink({
  uri: `ws://localhost:4000`,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem(AUTH_TOKEN)
    }
  }
});
// prod server
// const wsLink = new WebSocketLink({
//   uri: `wss://young-dawn-56853.herokuapp.com`,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       authToken: localStorage.getItem(AUTH_TOKEN)
//     }
//   }
// });

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
  typeDefs,
  resolvers
});

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

function IsLoggedIn() {
  const { data } = useQuery(IS_LOGGED_IN);
  return data.isLoggedIn ? <LinkListHooks /> : <Login />;
}

function GetMatches() {
  const { data } = useQuery();
}

cache.writeData({
  data: {
    isLoggedIn: !!localStorage.getItem("auth-token"),
    matches: [],
    teams: [],
    myCurrentMatch: {},
    currentUser: {}
  }
});

ReactDOM.render(
  <BrowserRouter forceRefresh={!supportsHistory}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
