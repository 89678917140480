import React, { Component, Fragment } from "react";
import { Query, useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import Match from "./Match/Match";
import { addTeamNamesToMatches } from "./../../helper_functions/addTeamNameToMatches";

const NEW_MATCHES_SUBSCRIPTION = gql`
  subscription MatchSub {
    newMatch {
      id
      awayTeam
      homeTeam
      awayTeamScore
      homeTeamScore
      balls
      strikes
      fouls
      outs
      currentInning
    }
  }
`;

const UPDATED_MATCHES_SUBSCRIPTION = gql`
  subscription MatchSub {
    updateMatch {
      id
      awayTeam
      homeTeam
      awayTeamScore
      homeTeamScore
      balls
      strikes
      fouls
      outs
      currentInning
    }
  }
`;

export const MATCH_FEED_QUERY = gql`
  query MatchFeedQuery {
    matchFeed {
      matchCount
      matches {
        id
        awayTeam
        homeTeam
        awayTeamScore
        homeTeamScore
        currentInning
        matchComplete
        balls
        strikes
        fouls
        outs
      }
    }
    teamFeed {
      teamCount
      teams {
        teamName
        id
      }
    }
  }
`;

// export const MATCH_FEED_QUERY = gql`
//   query MatchFeedQuery {
//     matches {
//       id
//       awayTeam
//       homeTeam
//       awayTeamScore
//       homeTeamScore
//       currentInning
//       matchComplete
//       balls
//       strikes
//       fouls
//       outs
//     }

//     teams {
//       teamName
//       id
//     }
//   }
// `;

class Matches extends Component {
  render() {
    // const client = useApolloClient();
    return (
      <Query query={MATCH_FEED_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <div>Fetching Matches...</div>;
          if (error) return <div>Error</div>;
          // console.log("data", data);
          // console.log("error", error);
          const matches = data.matchFeed.matches;
          const teams = data.teamFeed.teams;
          const matchesWithName = addTeamNamesToMatches(matches, teams);
          // client.writeData({ data: { matches: matchesWithName } });
          // console.log("matchesWithName", matchesWithName);

          return (
            <Fragment>
              <div>Matches</div>
              {matchesWithName.map((match, index) => (
                <Match key={match.id} match={match} index={index} />
              ))}
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default Matches;
